import logo from './logo.svg';
import './App.css';
import { useState, useEffect, useContext } from "react";
import Logo from "./resources/logos/logo.png"
import Phone1 from "./resources/images/voombu-home.png"
import Landing1 from "./resources/images/landing-1.png"
import Landing2 from "./resources/images/landing-2.png"
import Landing3 from "./resources/images/landing-3.png"
import Landing4 from "./resources/images/landing-4.png"
import Landing5 from "./resources/images/landing-5.png"
import Landing6 from "./resources/images/landing-10.png"
import Landing7 from "./resources/images/landing-11.png"
import Landing8 from "./resources/images/landing-12.png"
import Landing9 from "./resources/images/landing-13.png"
import Landing10 from "./resources/images/landing-14.png"
import Landing11 from "./resources/images/landing-15.png"
import Landing12 from "./resources/images/landing-16.png"
const Header = ({ title }) => {
  return (
  <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{title}</title>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.0.2/tailwind.min.css" />
  </head>)
}

const NavigationBar = ({ items, cta }) => {
  const [itemList, setItemList] = useState([]);
  useEffect(() => {
    setItemList(
      items.map( item => {
        return (<a href={'#'+item} className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-900">{item}</a>)
      })
    )
  })

  return (
    <section className="w-full px-8 text-gray-700 bg-white">
        <div className="container flex flex-col flex-wrap items-center justify-between py-5 mx-auto md:flex-row max-w-7xl">
            <div className="relative flex flex-col md:flex-row">
                <a href="#_" className="flex items-center mb-5 font-medium text-gray-900 lg:w-auto lg:items-center lg:justify-center md:mb-0">
                    <span className="mx-auto text-xl font-black leading-none text-gray-900 select-none"><img src={Logo} width="75%"/></span>
                </a>
                <nav className="flex flex-wrap items-center mb-5 text-base md:mb-0 md:pl-8 md:ml-8 md:border-l md:border-gray-200">
                    {itemList}
                </nav>
            </div>

            <div className="inline-flex items-center ml-5 space-x-6 lg:justify-end">
                <a href="mailto:info@voombu.app" className="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600">
                  ¡Contactenos!
                </a>
            </div>
        </div>
    </section>
  )
}

const MainSection = ({line1, line2, phrase, cta1, cta2}) => {
  //<img src="https://cdn.devdojo.com/images/november2020/hero-image.jpeg"/>
  /*

  <a href="#_" className="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-indigo-600 rounded-md sm:mb-0 hover:bg-indigo-700 sm:w-auto">
    {cta1}
  </a>
  <a href="#_" className="flex items-center px-6 py-3 text-gray-500 bg-gray-100 rounded-md hover:bg-gray-200 hover:text-gray-600">
    {cta2}
  </a>

  */
  return (
  <section className="px-2 py-32 bg-white md:px-0">
    <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
      <div className="flex flex-wrap items-center sm:-mx-3">
        <div className="w-full md:w-1/2 md:px-3">
          <div className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
              <span className="block xl:inline">{ line1 }</span>
              <span className="block text-indigo-600 xl:inline">{ line2 }</span>
            </h1>
            <p className="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">{ phrase }</p>
            <div className="relative flex flex-col sm:flex-row sm:space-x-4">

            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <div className="w-full h-auto overflow-hidden rounded-md ">
              <img src={Landing5} width="70%"/>
            </div>
        </div>
      </div>
    </div>
  </section>)
}

function App() {
  return (
    <div classNameName="App">
    <Header title={'VOOMBU'}/>
    <NavigationBar items={[]} cta={['']}/>
    <MainSection line1={'La primera '} line2={'Fintech para empresas'} phrase={"Todas las herramientas que necesitas para tu empresa en un solo lugar."} cta1={'Try for free'} cta2={'Learn More'}/>

    <section className="bg-gray-100">
        <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
            <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
                <div className="flex items-center justify-center col-span-1 md:col-span-2 lg:col-span-1">
                </div>
                <div className="flex items-center justify-center col-span-1 md:col-span-2 lg:col-span-1">
                </div>
                <div className="flex items-center justify-center col-span-1 md:col-span-2 lg:col-span-1">
                </div>
                <div className="flex items-center justify-center col-span-1 md:col-span-3 lg:col-span-1">
                </div>
                <div className="flex items-center justify-center col-span-2 md:col-span-3 lg:col-span-1">

                </div>
            </div>
        </div>
    </section>

    <section className="flex items-center justify-center py-10 text-white bg-white sm:py-16 md:py-24 lg:py-32">
        <div className="relative max-w-3xl px-10 text-center text-white auto lg:px-0">
            <div className="flex flex-col w-full md:flex-row">


                <div className="flex justify-between">
                    <h1 className="relative flex flex-col text-6xl font-extrabold text-left text-black">
                        X as a
                        <span>Service</span>
                        <span></span>
                    </h1>
                </div>

                <div className="relative top-0 right-0 h-64 mt-12 md:-mt-16 md:absolute md:h-96">
                    <img src={Landing7} className="object-cover mt-3 mr-5 h-80 lg:h-45"/>
                </div>
            </div>


            <div className="my-16 border-b border-gray-300 lg:my-24"></div>


            <h2 className="text-left text-gray-500 xl:text-xl">
                Bajo la modalidad <i>Todo como servicio</i> pagarás unicamente por los servicios que requiera tu empresa
            </h2>
        </div>
    </section>

    <section className="w-full bg-white pt-7 pb-7 md:pt-20 md:pb-24">
        <div className="box-border flex flex-col items-center content-center px-8 mx-auto leading-6 text-black border-0 border-gray-300 border-solid md:flex-row max-w-7xl lg:px-16">

            <div className="box-border relative w-full max-w-md px-4 mt-5 mb-4 -ml-5 text-center bg-no-repeat bg-contain border-solid md:ml-0 md:mt-0 md:max-w-none lg:mb-0 md:w-1/2 xl:pl-10">
                <img src={Landing8} className="p-2 pl-6 pr-5 xl:pl-16 xl:pr-20 " width="75%"/>
            </div>

            <div className="box-border order-first w-full text-black border-solid md:w-1/2 md:pl-10 md:order-none">
                <h2 className="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
                    Todas tus herramientas de gestión
                </h2>
                <p className="pt-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-lg">
                    Gestión automatizada al alcance de tu mano.
                </p>
                <ul className="p-0 m-0 leading-6 border-0 border-gray-300">
                    <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-indigo-700 rounded-full"><span className="text-sm font-bold">✓</span></span> E.R.P
                    </li>
                    <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-indigo-700 rounded-full"><span className="text-sm font-bold">✓</span></span> C.R.M
                    </li>
                    <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-indigo-700 rounded-full"><span className="text-sm font-bold">✓</span></span> Nóminas
                    </li>
                    <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-indigo-700 rounded-full"><span className="text-sm font-bold">✓</span></span> Integración bancaria
                    </li>
                </ul>
            </div>

        </div>
        <div className="box-border flex flex-col items-center content-center px-8 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-7xl lg:px-16">

            <div className="box-border w-full text-black border-solid md:w-1/2 md:pl-6 xl:pl-32">
                <h2 className="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
                    Primera herramienta de gestión Fintech
                </h2>
                <p className="pt-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-10 lg:text-lg">
                    100% integración E.R.P/C.R.M y nómina
                </p>
                <ul className="p-0 m-0 leading-6 border-0 border-gray-300">
                    <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-indigo-700 rounded-full"><span className="text-sm font-bold">✓</span></span> Consciliacieon inmediata
                    </li>
                    <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-indigo-700 rounded-full"><span className="text-sm font-bold">✓</span></span> Optimización financiera
                    </li>
                    <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-indigo-700 rounded-full"><span className="text-sm font-bold">✓</span></span> Pagos garantizados a proveedores (escrows)
                    </li>
                    <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                        <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-indigo-700 rounded-full"><span className="text-sm font-bold">✓</span></span> Benchmarking financiero
                    </li>
                </ul>
            </div>
            <div className="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
                <img src={Landing11} className="pl-4 sm:pr-10 xl:pl-10 lg:pr-32"/>
            </div>
        </div>
    </section>

    <section className="py-20 bg-gray-50">
      <div className="box-border flex flex-col items-center content-center px-8 mx-auto leading-6 text-black border-0 border-gray-300 border-solid md:flex-row max-w-7xl lg:px-16">

          <div className="box-border relative w-full max-w-md px-4 mt-5 mb-4 -ml-5 text-center bg-no-repeat bg-contain border-solid md:ml-0 md:mt-0 md:max-w-none lg:mb-0 md:w-1/2 xl:pl-10">
              <img src={Landing12} className="p-2 pl-6 pr-5 xl:pl-16 xl:pr-20 " width="75%"/>
          </div>

          <div className="box-border order-first w-full text-black border-solid md:w-1/2 md:pl-10 md:order-none">
              <h2 className="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
                  ¿Necesitas apoyo legal?
              </h2>
              <p className="pt-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-lg">
                  Solicita apoyo legal a un click de distancia
              </p>
              <ul className="p-0 m-0 leading-6 border-0 border-gray-300">
                  <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                      <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-indigo-700 rounded-full"><span className="text-sm font-bold">✓</span></span> Disponibles 24/7
                  </li>
                  <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                      <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-indigo-700 rounded-full"><span className="text-sm font-bold">✓</span></span> Solicitudes desde tu móvil
                  </li>
              </ul>
          </div>

      </div>

      <div className="box-border flex flex-col items-center content-center px-8 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row max-w-7xl lg:px-16">

          <div className="box-border w-full text-black border-solid md:w-1/2 md:pl-6 xl:pl-32">
              <h2 className="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
                  ¿Necesitas Espacios para tú negocio?
              </h2>
              <p className="pt-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-10 lg:text-lg">
                  Alquier de salas y espacios flexibles para tu negocio, todo en una sola app.
              </p>
              <ul className="p-0 m-0 leading-6 border-0 border-gray-300">
                  <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                      <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-indigo-700 rounded-full"><span className="text-sm font-bold">✓</span></span> Alquiler de salas y despachos
                  </li>
                  <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                      <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-indigo-700 rounded-full"><span className="text-sm font-bold">✓</span></span> Manejo de correspondencia
                  </li>
                  <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                      <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-indigo-700 rounded-full"><span className="text-sm font-bold">✓</span></span> Recepción de llamadas
                  </li>
                  <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
                      <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-indigo-700 rounded-full"><span className="text-sm font-bold">✓</span></span> Networking y eventos exclusivos
                  </li>
              </ul>
          </div>
          <div className="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
              <img src={Landing10} className="pl-4 sm:pr-10 xl:pl-10 lg:pr-32"/>
          </div>
      </div>
    </section>


    <section className="py-20 bg-white">
        <div className="px-4 mx-auto text-center max-w-7xl sm:px-6 lg:px-8">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl md:text-5xl xl:text-6xl">
                Primera Fintech para empresas con 360 grados de integración
            </h2>
            <p className="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Facturas, impuestos, gastos, clientes, nóminas, apoyo legal, fiscal y financiero, todo esto y mucho más al alcance de tu mano
            </p>
            <div className="flex justify-center mt-8 space-x-3">
              <a href="mailto:info@voombu.app" className="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600">
                ¡Contactenos!
              </a>
            </div>
        </div>
    </section>

    <section className="bg-white">
        <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
            <nav className="flex flex-wrap justify-center -mx-5 -my-2">

            </nav>
            <div className="flex justify-center mt-8 space-x-6">

            </div>
            <p className="mt-8 text-base leading-6 text-center text-gray-400">
                © 2021 VOOMBU Solutions. All rights reserved.
            </p>
        </div>
    </section>

    <script src="https://cdnjs.cloudflare.com/ajax/libs/alpinejs/2.8.0/alpine.js"></script>

    </div>
  );
}

export default App;
